/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processReferral = /* GraphQL */ `
  mutation ProcessReferral($input: ProcessReferralInput!) {
    processReferral(input: $input)
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications($input: DeleteNotificationsInput!) {
    deleteNotifications(input: $input)
  }
`;
export const deleteUserFromCognito = /* GraphQL */ `
  mutation DeleteUserFromCognito($input: DeleteUserFromCognitoInput!) {
    deleteUserFromCognito(input: $input)
  }
`;
export const sendMissedCallAlert = /* GraphQL */ `
  mutation SendMissedCallAlert($input: SendMissedCallAlertInput!) {
    sendMissedCallAlert(input: $input)
  }
`;
export const sendBroadcastPushNotification = /* GraphQL */ `
  mutation SendBroadcastPushNotification(
    $input: SendBroadcastPushNotificationInput!
  ) {
    sendBroadcastPushNotification(input: $input)
  }
`;
export const createStripeSubscription = /* GraphQL */ `
  mutation CreateStripeSubscription(
    $input: CreateStripeSubscriptionInput!
    $condition: ModelStripeSubscriptionConditionInput
  ) {
    createStripeSubscription(input: $input, condition: $condition) {
      id
      name
      description
      label
      icon
      price
      order
      stripePriceId
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStripeSubscription = /* GraphQL */ `
  mutation UpdateStripeSubscription(
    $input: UpdateStripeSubscriptionInput!
    $condition: ModelStripeSubscriptionConditionInput
  ) {
    updateStripeSubscription(input: $input, condition: $condition) {
      id
      name
      description
      label
      icon
      price
      order
      stripePriceId
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStripeSubscription = /* GraphQL */ `
  mutation DeleteStripeSubscription(
    $input: DeleteStripeSubscriptionInput!
    $condition: ModelStripeSubscriptionConditionInput
  ) {
    deleteStripeSubscription(input: $input, condition: $condition) {
      id
      name
      description
      label
      icon
      price
      order
      stripePriceId
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStripeProduct = /* GraphQL */ `
  mutation CreateStripeProduct(
    $input: CreateStripeProductInput!
    $condition: ModelStripeProductConditionInput
  ) {
    createStripeProduct(input: $input, condition: $condition) {
      id
      name
      description
      label
      icon
      price
      oldPrice
      oldUnitPrice
      unitPrice
      order
      stripePriceId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStripeProduct = /* GraphQL */ `
  mutation UpdateStripeProduct(
    $input: UpdateStripeProductInput!
    $condition: ModelStripeProductConditionInput
  ) {
    updateStripeProduct(input: $input, condition: $condition) {
      id
      name
      description
      label
      icon
      price
      oldPrice
      oldUnitPrice
      unitPrice
      order
      stripePriceId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStripeProduct = /* GraphQL */ `
  mutation DeleteStripeProduct(
    $input: DeleteStripeProductInput!
    $condition: ModelStripeProductConditionInput
  ) {
    deleteStripeProduct(input: $input, condition: $condition) {
      id
      name
      description
      label
      icon
      price
      oldPrice
      oldUnitPrice
      unitPrice
      order
      stripePriceId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      extId
      username
      name
      email
      phone
      role
      settings
      expert
      expertRating
      verified
      active
      deleted
      available
      online
      credits
      media
      tagline
      profile
      location
      website
      linkedIn
      instagram
      interests
      favoriteQuote
      superpowerText
      socialMedia
      promotionalContent
      dob
      age
      ageRange
      gender
      orientation
      relationshipStatus
      availability
      callRate
      payoutRate
      stripeId
      referrer
      referralCodeUsage {
        nextToken
        __typename
      }
      expertTypeId
      expertType {
        id
        name
        description
        media
        callRate
        sort
        createdAt
        updatedAt
        __typename
      }
      posts {
        nextToken
        __typename
      }
      subscriptions {
        nextToken
        __typename
      }
      calls {
        nextToken
        __typename
      }
      expertCalls {
        nextToken
        __typename
      }
      courses {
        nextToken
        __typename
      }
      courseBookmarks {
        nextToken
        __typename
      }
      userCourses {
        nextToken
        __typename
      }
      userLessons {
        nextToken
        __typename
      }
      chats {
        nextToken
        __typename
      }
      devices {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      hotlineCredits {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      extId
      username
      name
      email
      phone
      role
      settings
      expert
      expertRating
      verified
      active
      deleted
      available
      online
      credits
      media
      tagline
      profile
      location
      website
      linkedIn
      instagram
      interests
      favoriteQuote
      superpowerText
      socialMedia
      promotionalContent
      dob
      age
      ageRange
      gender
      orientation
      relationshipStatus
      availability
      callRate
      payoutRate
      stripeId
      referrer
      referralCodeUsage {
        nextToken
        __typename
      }
      expertTypeId
      expertType {
        id
        name
        description
        media
        callRate
        sort
        createdAt
        updatedAt
        __typename
      }
      posts {
        nextToken
        __typename
      }
      subscriptions {
        nextToken
        __typename
      }
      calls {
        nextToken
        __typename
      }
      expertCalls {
        nextToken
        __typename
      }
      courses {
        nextToken
        __typename
      }
      courseBookmarks {
        nextToken
        __typename
      }
      userCourses {
        nextToken
        __typename
      }
      userLessons {
        nextToken
        __typename
      }
      chats {
        nextToken
        __typename
      }
      devices {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      hotlineCredits {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      extId
      username
      name
      email
      phone
      role
      settings
      expert
      expertRating
      verified
      active
      deleted
      available
      online
      credits
      media
      tagline
      profile
      location
      website
      linkedIn
      instagram
      interests
      favoriteQuote
      superpowerText
      socialMedia
      promotionalContent
      dob
      age
      ageRange
      gender
      orientation
      relationshipStatus
      availability
      callRate
      payoutRate
      stripeId
      referrer
      referralCodeUsage {
        nextToken
        __typename
      }
      expertTypeId
      expertType {
        id
        name
        description
        media
        callRate
        sort
        createdAt
        updatedAt
        __typename
      }
      posts {
        nextToken
        __typename
      }
      subscriptions {
        nextToken
        __typename
      }
      calls {
        nextToken
        __typename
      }
      expertCalls {
        nextToken
        __typename
      }
      courses {
        nextToken
        __typename
      }
      courseBookmarks {
        nextToken
        __typename
      }
      userCourses {
        nextToken
        __typename
      }
      userLessons {
        nextToken
        __typename
      }
      chats {
        nextToken
        __typename
      }
      devices {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      transactions {
        nextToken
        __typename
      }
      hotlineCredits {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserDevice = /* GraphQL */ `
  mutation CreateUserDevice(
    $input: CreateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    createUserDevice(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      type
      name
      meta
      tokenType
      token
      arn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserDevice = /* GraphQL */ `
  mutation UpdateUserDevice(
    $input: UpdateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    updateUserDevice(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      type
      name
      meta
      tokenType
      token
      arn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserDevice = /* GraphQL */ `
  mutation DeleteUserDevice(
    $input: DeleteUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    deleteUserDevice(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      type
      name
      meta
      tokenType
      token
      arn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserStripeSubscription = /* GraphQL */ `
  mutation CreateUserStripeSubscription(
    $input: CreateUserStripeSubscriptionInput!
    $condition: ModelUserStripeSubscriptionConditionInput
  ) {
    createUserStripeSubscription(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      subscriptionId
      subscription {
        id
        name
        description
        label
        icon
        price
        order
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      stripeSubscriptionId
      status
      startAt
      expireAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserStripeSubscription = /* GraphQL */ `
  mutation UpdateUserStripeSubscription(
    $input: UpdateUserStripeSubscriptionInput!
    $condition: ModelUserStripeSubscriptionConditionInput
  ) {
    updateUserStripeSubscription(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      subscriptionId
      subscription {
        id
        name
        description
        label
        icon
        price
        order
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      stripeSubscriptionId
      status
      startAt
      expireAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserStripeSubscription = /* GraphQL */ `
  mutation DeleteUserStripeSubscription(
    $input: DeleteUserStripeSubscriptionInput!
    $condition: ModelUserStripeSubscriptionConditionInput
  ) {
    deleteUserStripeSubscription(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      subscriptionId
      subscription {
        id
        name
        description
        label
        icon
        price
        order
        stripePriceId
        createdAt
        updatedAt
        __typename
      }
      stripeSubscriptionId
      status
      startAt
      expireAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExpertType = /* GraphQL */ `
  mutation CreateExpertType(
    $input: CreateExpertTypeInput!
    $condition: ModelExpertTypeConditionInput
  ) {
    createExpertType(input: $input, condition: $condition) {
      id
      name
      description
      media
      callRate
      sort
      experts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateExpertType = /* GraphQL */ `
  mutation UpdateExpertType(
    $input: UpdateExpertTypeInput!
    $condition: ModelExpertTypeConditionInput
  ) {
    updateExpertType(input: $input, condition: $condition) {
      id
      name
      description
      media
      callRate
      sort
      experts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteExpertType = /* GraphQL */ `
  mutation DeleteExpertType(
    $input: DeleteExpertTypeInput!
    $condition: ModelExpertTypeConditionInput
  ) {
    deleteExpertType(input: $input, condition: $condition) {
      id
      name
      description
      media
      callRate
      sort
      experts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCallLog = /* GraphQL */ `
  mutation CreateCallLog(
    $input: CreateCallLogInput!
    $condition: ModelCallLogConditionInput
  ) {
    createCallLog(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      expertId
      expert {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      callStart
      callEnd
      callDuration
      callRate
      callAmount
      payoutRate
      payoutAmount
      payoutDate
      payout
      userAudioRating
      userCallRating
      userFeedback
      userRatingAt
      expertAudioRating
      expertCallRating
      expertFeedback
      expertRatingAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCallLog = /* GraphQL */ `
  mutation UpdateCallLog(
    $input: UpdateCallLogInput!
    $condition: ModelCallLogConditionInput
  ) {
    updateCallLog(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      expertId
      expert {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      callStart
      callEnd
      callDuration
      callRate
      callAmount
      payoutRate
      payoutAmount
      payoutDate
      payout
      userAudioRating
      userCallRating
      userFeedback
      userRatingAt
      expertAudioRating
      expertCallRating
      expertFeedback
      expertRatingAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCallLog = /* GraphQL */ `
  mutation DeleteCallLog(
    $input: DeleteCallLogInput!
    $condition: ModelCallLogConditionInput
  ) {
    deleteCallLog(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      expertId
      expert {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      callStart
      callEnd
      callDuration
      callRate
      callAmount
      payoutRate
      payoutAmount
      payoutDate
      payout
      userAudioRating
      userCallRating
      userFeedback
      userRatingAt
      expertAudioRating
      expertCallRating
      expertFeedback
      expertRatingAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserTransaction = /* GraphQL */ `
  mutation CreateUserTransaction(
    $input: CreateUserTransactionInput!
    $condition: ModelUserTransactionConditionInput
  ) {
    createUserTransaction(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserTransaction = /* GraphQL */ `
  mutation UpdateUserTransaction(
    $input: UpdateUserTransactionInput!
    $condition: ModelUserTransactionConditionInput
  ) {
    updateUserTransaction(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserTransaction = /* GraphQL */ `
  mutation DeleteUserTransaction(
    $input: DeleteUserTransactionInput!
    $condition: ModelUserTransactionConditionInput
  ) {
    deleteUserTransaction(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserHotlineCredits = /* GraphQL */ `
  mutation CreateUserHotlineCredits(
    $input: CreateUserHotlineCreditsInput!
    $condition: ModelUserHotlineCreditsConditionInput
  ) {
    createUserHotlineCredits(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      description
      credits
      transaction {
        id
        userId
        amount
        createdAt
        updatedAt
        __typename
      }
      callLog {
        id
        userId
        expertId
        callStart
        callEnd
        callDuration
        callRate
        callAmount
        payoutRate
        payoutAmount
        payoutDate
        payout
        userAudioRating
        userCallRating
        userFeedback
        userRatingAt
        expertAudioRating
        expertCallRating
        expertFeedback
        expertRatingAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userHotlineCreditsTransactionId
      userHotlineCreditsCallLogId
      __typename
    }
  }
`;
export const updateUserHotlineCredits = /* GraphQL */ `
  mutation UpdateUserHotlineCredits(
    $input: UpdateUserHotlineCreditsInput!
    $condition: ModelUserHotlineCreditsConditionInput
  ) {
    updateUserHotlineCredits(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      description
      credits
      transaction {
        id
        userId
        amount
        createdAt
        updatedAt
        __typename
      }
      callLog {
        id
        userId
        expertId
        callStart
        callEnd
        callDuration
        callRate
        callAmount
        payoutRate
        payoutAmount
        payoutDate
        payout
        userAudioRating
        userCallRating
        userFeedback
        userRatingAt
        expertAudioRating
        expertCallRating
        expertFeedback
        expertRatingAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userHotlineCreditsTransactionId
      userHotlineCreditsCallLogId
      __typename
    }
  }
`;
export const deleteUserHotlineCredits = /* GraphQL */ `
  mutation DeleteUserHotlineCredits(
    $input: DeleteUserHotlineCreditsInput!
    $condition: ModelUserHotlineCreditsConditionInput
  ) {
    deleteUserHotlineCredits(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      description
      credits
      transaction {
        id
        userId
        amount
        createdAt
        updatedAt
        __typename
      }
      callLog {
        id
        userId
        expertId
        callStart
        callEnd
        callDuration
        callRate
        callAmount
        payoutRate
        payoutAmount
        payoutDate
        payout
        userAudioRating
        userCallRating
        userFeedback
        userRatingAt
        expertAudioRating
        expertCallRating
        expertFeedback
        expertRatingAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userHotlineCreditsTransactionId
      userHotlineCreditsCallLogId
      __typename
    }
  }
`;
export const createPostCategory = /* GraphQL */ `
  mutation CreatePostCategory(
    $input: CreatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    createPostCategory(input: $input, condition: $condition) {
      id
      name
      thumbnail
      cover
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePostCategory = /* GraphQL */ `
  mutation UpdatePostCategory(
    $input: UpdatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    updatePostCategory(input: $input, condition: $condition) {
      id
      name
      thumbnail
      cover
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePostCategory = /* GraphQL */ `
  mutation DeletePostCategory(
    $input: DeletePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    deletePostCategory(input: $input, condition: $condition) {
      id
      name
      thumbnail
      cover
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      authorId
      author {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      title
      description
      attachments
      thumbnail
      status
      publishedAt
      likeCount
      commentCount
      reportCount
      category {
        id
        name
        thumbnail
        cover
        createdAt
        updatedAt
        __typename
      }
      likes {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      postCategoryId
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      authorId
      author {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      title
      description
      attachments
      thumbnail
      status
      publishedAt
      likeCount
      commentCount
      reportCount
      category {
        id
        name
        thumbnail
        cover
        createdAt
        updatedAt
        __typename
      }
      likes {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      postCategoryId
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      authorId
      author {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      title
      description
      attachments
      thumbnail
      status
      publishedAt
      likeCount
      commentCount
      reportCount
      category {
        id
        name
        thumbnail
        cover
        createdAt
        updatedAt
        __typename
      }
      likes {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      postCategoryId
      __typename
    }
  }
`;
export const createPostLike = /* GraphQL */ `
  mutation CreatePostLike(
    $input: CreatePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    createPostLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      publishedAt
      createdAt
      updatedAt
      postLikeUserId
      __typename
    }
  }
`;
export const updatePostLike = /* GraphQL */ `
  mutation UpdatePostLike(
    $input: UpdatePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    updatePostLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      publishedAt
      createdAt
      updatedAt
      postLikeUserId
      __typename
    }
  }
`;
export const deletePostLike = /* GraphQL */ `
  mutation DeletePostLike(
    $input: DeletePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    deletePostLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      publishedAt
      createdAt
      updatedAt
      postLikeUserId
      __typename
    }
  }
`;
export const createPostComment = /* GraphQL */ `
  mutation CreatePostComment(
    $input: CreatePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    createPostComment(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      comment
      publishedAt
      likeCount
      replyCount
      reportCount
      likes {
        nextToken
        __typename
      }
      replies {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      postCommentUserId
      __typename
    }
  }
`;
export const updatePostComment = /* GraphQL */ `
  mutation UpdatePostComment(
    $input: UpdatePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    updatePostComment(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      comment
      publishedAt
      likeCount
      replyCount
      reportCount
      likes {
        nextToken
        __typename
      }
      replies {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      postCommentUserId
      __typename
    }
  }
`;
export const deletePostComment = /* GraphQL */ `
  mutation DeletePostComment(
    $input: DeletePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    deletePostComment(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      comment
      publishedAt
      likeCount
      replyCount
      reportCount
      likes {
        nextToken
        __typename
      }
      replies {
        nextToken
        __typename
      }
      reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      postCommentUserId
      __typename
    }
  }
`;
export const createPostReport = /* GraphQL */ `
  mutation CreatePostReport(
    $input: CreatePostReportInput!
    $condition: ModelPostReportConditionInput
  ) {
    createPostReport(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      reason
      publishedAt
      createdAt
      updatedAt
      postReportUserId
      __typename
    }
  }
`;
export const updatePostReport = /* GraphQL */ `
  mutation UpdatePostReport(
    $input: UpdatePostReportInput!
    $condition: ModelPostReportConditionInput
  ) {
    updatePostReport(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      reason
      publishedAt
      createdAt
      updatedAt
      postReportUserId
      __typename
    }
  }
`;
export const deletePostReport = /* GraphQL */ `
  mutation DeletePostReport(
    $input: DeletePostReportInput!
    $condition: ModelPostReportConditionInput
  ) {
    deletePostReport(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        authorId
        title
        description
        attachments
        thumbnail
        status
        publishedAt
        likeCount
        commentCount
        reportCount
        createdAt
        updatedAt
        postCategoryId
        __typename
      }
      reason
      publishedAt
      createdAt
      updatedAt
      postReportUserId
      __typename
    }
  }
`;
export const createPostCommentLike = /* GraphQL */ `
  mutation CreatePostCommentLike(
    $input: CreatePostCommentLikeInput!
    $condition: ModelPostCommentLikeConditionInput
  ) {
    createPostCommentLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      publishedAt
      createdAt
      updatedAt
      postCommentLikeUserId
      __typename
    }
  }
`;
export const updatePostCommentLike = /* GraphQL */ `
  mutation UpdatePostCommentLike(
    $input: UpdatePostCommentLikeInput!
    $condition: ModelPostCommentLikeConditionInput
  ) {
    updatePostCommentLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      publishedAt
      createdAt
      updatedAt
      postCommentLikeUserId
      __typename
    }
  }
`;
export const deletePostCommentLike = /* GraphQL */ `
  mutation DeletePostCommentLike(
    $input: DeletePostCommentLikeInput!
    $condition: ModelPostCommentLikeConditionInput
  ) {
    deletePostCommentLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      publishedAt
      createdAt
      updatedAt
      postCommentLikeUserId
      __typename
    }
  }
`;
export const createPostCommentReply = /* GraphQL */ `
  mutation CreatePostCommentReply(
    $input: CreatePostCommentReplyInput!
    $condition: ModelPostCommentReplyConditionInput
  ) {
    createPostCommentReply(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      comment
      publishedAt
      createdAt
      updatedAt
      postCommentReplyUserId
      __typename
    }
  }
`;
export const updatePostCommentReply = /* GraphQL */ `
  mutation UpdatePostCommentReply(
    $input: UpdatePostCommentReplyInput!
    $condition: ModelPostCommentReplyConditionInput
  ) {
    updatePostCommentReply(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      comment
      publishedAt
      createdAt
      updatedAt
      postCommentReplyUserId
      __typename
    }
  }
`;
export const deletePostCommentReply = /* GraphQL */ `
  mutation DeletePostCommentReply(
    $input: DeletePostCommentReplyInput!
    $condition: ModelPostCommentReplyConditionInput
  ) {
    deletePostCommentReply(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      comment
      publishedAt
      createdAt
      updatedAt
      postCommentReplyUserId
      __typename
    }
  }
`;
export const createPostCommentReport = /* GraphQL */ `
  mutation CreatePostCommentReport(
    $input: CreatePostCommentReportInput!
    $condition: ModelPostCommentReportConditionInput
  ) {
    createPostCommentReport(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      reason
      createdAt
      updatedAt
      postCommentReportUserId
      __typename
    }
  }
`;
export const updatePostCommentReport = /* GraphQL */ `
  mutation UpdatePostCommentReport(
    $input: UpdatePostCommentReportInput!
    $condition: ModelPostCommentReportConditionInput
  ) {
    updatePostCommentReport(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      reason
      createdAt
      updatedAt
      postCommentReportUserId
      __typename
    }
  }
`;
export const deletePostCommentReport = /* GraphQL */ `
  mutation DeletePostCommentReport(
    $input: DeletePostCommentReportInput!
    $condition: ModelPostCommentReportConditionInput
  ) {
    deletePostCommentReport(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      postCommentId
      postComment {
        id
        postId
        comment
        publishedAt
        likeCount
        replyCount
        reportCount
        createdAt
        updatedAt
        postCommentUserId
        __typename
      }
      reason
      createdAt
      updatedAt
      postCommentReportUserId
      __typename
    }
  }
`;
export const createCourseCategory = /* GraphQL */ `
  mutation CreateCourseCategory(
    $input: CreateCourseCategoryInput!
    $condition: ModelCourseCategoryConditionInput
  ) {
    createCourseCategory(input: $input, condition: $condition) {
      id
      name
      thumbnail
      cover
      courseCategoryRelation {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCourseCategory = /* GraphQL */ `
  mutation UpdateCourseCategory(
    $input: UpdateCourseCategoryInput!
    $condition: ModelCourseCategoryConditionInput
  ) {
    updateCourseCategory(input: $input, condition: $condition) {
      id
      name
      thumbnail
      cover
      courseCategoryRelation {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCourseCategory = /* GraphQL */ `
  mutation DeleteCourseCategory(
    $input: DeleteCourseCategoryInput!
    $condition: ModelCourseCategoryConditionInput
  ) {
    deleteCourseCategory(input: $input, condition: $condition) {
      id
      name
      thumbnail
      cover
      courseCategoryRelation {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      instructorId
      instructor {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      title
      description
      duration
      faq
      free
      trending
      thumbnail
      cover
      trailer
      lessonCount
      likeCount
      publishedAt
      lessons {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      bookmarks {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      courseCategoryRelation {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      instructorId
      instructor {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      title
      description
      duration
      faq
      free
      trending
      thumbnail
      cover
      trailer
      lessonCount
      likeCount
      publishedAt
      lessons {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      bookmarks {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      courseCategoryRelation {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      instructorId
      instructor {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      title
      description
      duration
      faq
      free
      trending
      thumbnail
      cover
      trailer
      lessonCount
      likeCount
      publishedAt
      lessons {
        nextToken
        __typename
      }
      reviews {
        nextToken
        __typename
      }
      likes {
        nextToken
        __typename
      }
      bookmarks {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      courseCategoryRelation {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCourseCategoryRelation = /* GraphQL */ `
  mutation CreateCourseCategoryRelation(
    $input: CreateCourseCategoryRelationInput!
    $condition: ModelCourseCategoryRelationConditionInput
  ) {
    createCourseCategoryRelation(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      courseCategoryId
      courseCategory {
        id
        name
        thumbnail
        cover
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCourseCategoryRelation = /* GraphQL */ `
  mutation UpdateCourseCategoryRelation(
    $input: UpdateCourseCategoryRelationInput!
    $condition: ModelCourseCategoryRelationConditionInput
  ) {
    updateCourseCategoryRelation(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      courseCategoryId
      courseCategory {
        id
        name
        thumbnail
        cover
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCourseCategoryRelation = /* GraphQL */ `
  mutation DeleteCourseCategoryRelation(
    $input: DeleteCourseCategoryRelationInput!
    $condition: ModelCourseCategoryRelationConditionInput
  ) {
    deleteCourseCategoryRelation(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      courseCategoryId
      courseCategory {
        id
        name
        thumbnail
        cover
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      position
      title
      description
      takeaways
      thumbnail
      cover
      audio
      video
      content
      slides
      attachments
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      position
      title
      description
      takeaways
      thumbnail
      cover
      audio
      video
      content
      slides
      attachments
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      position
      title
      description
      takeaways
      thumbnail
      cover
      audio
      video
      content
      slides
      attachments
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCourseLike = /* GraphQL */ `
  mutation CreateCourseLike(
    $input: CreateCourseLikeInput!
    $condition: ModelCourseLikeConditionInput
  ) {
    createCourseLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      courseLikeUserId
      __typename
    }
  }
`;
export const updateCourseLike = /* GraphQL */ `
  mutation UpdateCourseLike(
    $input: UpdateCourseLikeInput!
    $condition: ModelCourseLikeConditionInput
  ) {
    updateCourseLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      courseLikeUserId
      __typename
    }
  }
`;
export const deleteCourseLike = /* GraphQL */ `
  mutation DeleteCourseLike(
    $input: DeleteCourseLikeInput!
    $condition: ModelCourseLikeConditionInput
  ) {
    deleteCourseLike(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      courseLikeUserId
      __typename
    }
  }
`;
export const createCourseBookmark = /* GraphQL */ `
  mutation CreateCourseBookmark(
    $input: CreateCourseBookmarkInput!
    $condition: ModelCourseBookmarkConditionInput
  ) {
    createCourseBookmark(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCourseBookmark = /* GraphQL */ `
  mutation UpdateCourseBookmark(
    $input: UpdateCourseBookmarkInput!
    $condition: ModelCourseBookmarkConditionInput
  ) {
    updateCourseBookmark(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCourseBookmark = /* GraphQL */ `
  mutation DeleteCourseBookmark(
    $input: DeleteCourseBookmarkInput!
    $condition: ModelCourseBookmarkConditionInput
  ) {
    deleteCourseBookmark(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCourseReview = /* GraphQL */ `
  mutation CreateCourseReview(
    $input: CreateCourseReviewInput!
    $condition: ModelCourseReviewConditionInput
  ) {
    createCourseReview(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      rating
      review
      createdAt
      updatedAt
      courseReviewUserId
      __typename
    }
  }
`;
export const updateCourseReview = /* GraphQL */ `
  mutation UpdateCourseReview(
    $input: UpdateCourseReviewInput!
    $condition: ModelCourseReviewConditionInput
  ) {
    updateCourseReview(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      rating
      review
      createdAt
      updatedAt
      courseReviewUserId
      __typename
    }
  }
`;
export const deleteCourseReview = /* GraphQL */ `
  mutation DeleteCourseReview(
    $input: DeleteCourseReviewInput!
    $condition: ModelCourseReviewConditionInput
  ) {
    deleteCourseReview(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      rating
      review
      createdAt
      updatedAt
      courseReviewUserId
      __typename
    }
  }
`;
export const createUserCourse = /* GraphQL */ `
  mutation CreateUserCourse(
    $input: CreateUserCourseInput!
    $condition: ModelUserCourseConditionInput
  ) {
    createUserCourse(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      progress
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserCourse = /* GraphQL */ `
  mutation UpdateUserCourse(
    $input: UpdateUserCourseInput!
    $condition: ModelUserCourseConditionInput
  ) {
    updateUserCourse(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      progress
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserCourse = /* GraphQL */ `
  mutation DeleteUserCourse(
    $input: DeleteUserCourseInput!
    $condition: ModelUserCourseConditionInput
  ) {
    deleteUserCourse(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      courseId
      course {
        id
        instructorId
        title
        description
        duration
        faq
        free
        trending
        thumbnail
        cover
        trailer
        lessonCount
        likeCount
        publishedAt
        createdAt
        updatedAt
        __typename
      }
      progress
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserLesson = /* GraphQL */ `
  mutation CreateUserLesson(
    $input: CreateUserLessonInput!
    $condition: ModelUserLessonConditionInput
  ) {
    createUserLesson(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      lessonId
      lesson {
        id
        courseId
        position
        title
        description
        takeaways
        thumbnail
        cover
        audio
        video
        content
        slides
        attachments
        createdAt
        updatedAt
        __typename
      }
      progress
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserLesson = /* GraphQL */ `
  mutation UpdateUserLesson(
    $input: UpdateUserLessonInput!
    $condition: ModelUserLessonConditionInput
  ) {
    updateUserLesson(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      lessonId
      lesson {
        id
        courseId
        position
        title
        description
        takeaways
        thumbnail
        cover
        audio
        video
        content
        slides
        attachments
        createdAt
        updatedAt
        __typename
      }
      progress
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserLesson = /* GraphQL */ `
  mutation DeleteUserLesson(
    $input: DeleteUserLessonInput!
    $condition: ModelUserLessonConditionInput
  ) {
    deleteUserLesson(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      lessonId
      lesson {
        id
        courseId
        position
        title
        description
        takeaways
        thumbnail
        cover
        audio
        video
        content
        slides
        attachments
        createdAt
        updatedAt
        __typename
      }
      progress
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      users {
        nextToken
        __typename
      }
      message {
        id
        chatId
        message
        media
        createdAt
        updatedAt
        messageUserId
        __typename
      }
      messages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      chatMessageId
      __typename
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      users {
        nextToken
        __typename
      }
      message {
        id
        chatId
        message
        media
        createdAt
        updatedAt
        messageUserId
        __typename
      }
      messages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      chatMessageId
      __typename
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      users {
        nextToken
        __typename
      }
      message {
        id
        chatId
        message
        media
        createdAt
        updatedAt
        messageUserId
        __typename
      }
      messages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      chatMessageId
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      chatId
      chat {
        id
        createdAt
        updatedAt
        chatMessageId
        __typename
      }
      message
      media
      createdAt
      updatedAt
      messageUserId
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      chatId
      chat {
        id
        createdAt
        updatedAt
        chatMessageId
        __typename
      }
      message
      media
      createdAt
      updatedAt
      messageUserId
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      chatId
      chat {
        id
        createdAt
        updatedAt
        chatMessageId
        __typename
      }
      message
      media
      createdAt
      updatedAt
      messageUserId
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      sender {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      type
      typeId
      title
      message
      sent
      read
      deleted
      createdAt
      updatedAt
      notificationSenderId
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      sender {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      type
      typeId
      title
      message
      sent
      read
      deleted
      createdAt
      updatedAt
      notificationSenderId
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      sender {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      type
      typeId
      title
      message
      sent
      read
      deleted
      createdAt
      updatedAt
      notificationSenderId
      __typename
    }
  }
`;
export const createReferralCode = /* GraphQL */ `
  mutation CreateReferralCode(
    $input: CreateReferralCodeInput!
    $condition: ModelReferralCodeConditionInput
  ) {
    createReferralCode(input: $input, condition: $condition) {
      id
      code
      limit
      expireAt
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      userPurchaseCredits
      userRegistrationCredits
      referrerPurchaseCredits
      referrerRegistrationCredits
      referralCodeUsage {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      referralCodeUserId
      __typename
    }
  }
`;
export const updateReferralCode = /* GraphQL */ `
  mutation UpdateReferralCode(
    $input: UpdateReferralCodeInput!
    $condition: ModelReferralCodeConditionInput
  ) {
    updateReferralCode(input: $input, condition: $condition) {
      id
      code
      limit
      expireAt
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      userPurchaseCredits
      userRegistrationCredits
      referrerPurchaseCredits
      referrerRegistrationCredits
      referralCodeUsage {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      referralCodeUserId
      __typename
    }
  }
`;
export const deleteReferralCode = /* GraphQL */ `
  mutation DeleteReferralCode(
    $input: DeleteReferralCodeInput!
    $condition: ModelReferralCodeConditionInput
  ) {
    deleteReferralCode(input: $input, condition: $condition) {
      id
      code
      limit
      expireAt
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      userPurchaseCredits
      userRegistrationCredits
      referrerPurchaseCredits
      referrerRegistrationCredits
      referralCodeUsage {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      referralCodeUserId
      __typename
    }
  }
`;
export const createReferralCodeUsage = /* GraphQL */ `
  mutation CreateReferralCodeUsage(
    $input: CreateReferralCodeUsageInput!
    $condition: ModelReferralCodeUsageConditionInput
  ) {
    createReferralCodeUsage(input: $input, condition: $condition) {
      id
      type
      userId
      usedOn
      referrerId
      refferrer {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      referralCodeId
      referralCode {
        id
        code
        limit
        expireAt
        userPurchaseCredits
        userRegistrationCredits
        referrerPurchaseCredits
        referrerRegistrationCredits
        createdAt
        updatedAt
        referralCodeUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReferralCodeUsage = /* GraphQL */ `
  mutation UpdateReferralCodeUsage(
    $input: UpdateReferralCodeUsageInput!
    $condition: ModelReferralCodeUsageConditionInput
  ) {
    updateReferralCodeUsage(input: $input, condition: $condition) {
      id
      type
      userId
      usedOn
      referrerId
      refferrer {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      referralCodeId
      referralCode {
        id
        code
        limit
        expireAt
        userPurchaseCredits
        userRegistrationCredits
        referrerPurchaseCredits
        referrerRegistrationCredits
        createdAt
        updatedAt
        referralCodeUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReferralCodeUsage = /* GraphQL */ `
  mutation DeleteReferralCodeUsage(
    $input: DeleteReferralCodeUsageInput!
    $condition: ModelReferralCodeUsageConditionInput
  ) {
    deleteReferralCodeUsage(input: $input, condition: $condition) {
      id
      type
      userId
      usedOn
      referrerId
      refferrer {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      referralCodeId
      referralCode {
        id
        code
        limit
        expireAt
        userPurchaseCredits
        userRegistrationCredits
        referrerPurchaseCredits
        referrerRegistrationCredits
        createdAt
        updatedAt
        referralCodeUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserChatRelation = /* GraphQL */ `
  mutation CreateUserChatRelation(
    $input: CreateUserChatRelationInput!
    $condition: ModelUserChatRelationConditionInput
  ) {
    createUserChatRelation(input: $input, condition: $condition) {
      id
      userId
      chatId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      chat {
        id
        createdAt
        updatedAt
        chatMessageId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserChatRelation = /* GraphQL */ `
  mutation UpdateUserChatRelation(
    $input: UpdateUserChatRelationInput!
    $condition: ModelUserChatRelationConditionInput
  ) {
    updateUserChatRelation(input: $input, condition: $condition) {
      id
      userId
      chatId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      chat {
        id
        createdAt
        updatedAt
        chatMessageId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserChatRelation = /* GraphQL */ `
  mutation DeleteUserChatRelation(
    $input: DeleteUserChatRelationInput!
    $condition: ModelUserChatRelationConditionInput
  ) {
    deleteUserChatRelation(input: $input, condition: $condition) {
      id
      userId
      chatId
      user {
        id
        extId
        username
        name
        email
        phone
        role
        settings
        expert
        expertRating
        verified
        active
        deleted
        available
        online
        credits
        media
        tagline
        profile
        location
        website
        linkedIn
        instagram
        interests
        favoriteQuote
        superpowerText
        socialMedia
        promotionalContent
        dob
        age
        ageRange
        gender
        orientation
        relationshipStatus
        availability
        callRate
        payoutRate
        stripeId
        referrer
        expertTypeId
        createdAt
        updatedAt
        __typename
      }
      chat {
        id
        createdAt
        updatedAt
        chatMessageId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
