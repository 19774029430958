import { Amplify, Hub } from "aws-amplify";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import awsConfig from "./aws-exports";
import Layout from "./components/Layout";
import AddCourse from "./pages/AddCourse";
import AddCourseTag from "./pages/AddCourseTag";
import AddExpertType from "./pages/AddExpertType";
import AddLessons from "./pages/AddLessons";
import AddPostTopic from "./pages/AddPostTopic";
import Articles from "./pages/Articles";
import CallHistory from "./pages/CallHistory";
import CourseDetails from "./pages/CourseDetails";
import CourseTags from "./pages/CourseTags";
import Courses from "./pages/Courses";
import Dashboard from "./pages/Dashboard";
import EditExpert from "./pages/EditExpert";
import EditPost from "./pages/EditPost";
import EditUser from "./pages/EditUser";
import Error from "./pages/Error";
import ExpertDetail from "./pages/ExpertDetail";
import ExpertTypes from "./pages/ExpertTypes";
import Experts from "./pages/Experts";
import PostDetails from "./pages/PostDetails";
import PostTopic from "./pages/PostTopic";
import Posts from "./pages/Posts";
import Profile from "./pages/Profile";
import PushNotifications from "./pages/PushNotifications";
import ReferralCodes from "./pages/ReferralCodes";
import Subscriptions from "./pages/Subscriptions";
import Transactions from "./pages/Transactions";
import UserDetails from "./pages/UserDetails";
import Users from "./pages/Users";
import ForgetPassword from "./pages/auth/ForgetPassword";
import SignIn from "./pages/auth/SignIn";
import AboutUs from "./pages/other/AboutUs";
import ContactUs from "./pages/other/ContactUs";
import Faq from "./pages/other/Faq";
import Guidelines from "./pages/other/Guidelines";
import Privacy from "./pages/other/Privacy";
import TermsOfUse from "./pages/other/TermsOfUse";
import AddPost from "./pages/AddPost";
import ReferralPurchaseHistory from "./pages/ReferralPurchaseHistory";
import LiveStreams from "./pages/LiveStreams";
import LiveStreamDetails from "./pages/LiveStreamDetails";
import EditLiveStream from "./pages/EditLiveStream";

function App() {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  const [
    mobileSignIn,
    expertSignIn,
    localRedirectSignIn,
    productionRedirectSignIn,
  ] = awsConfig.oauth.redirectSignIn.split(",");

  const [
    mobileSignout,
    expertSignOut,
    localRedirectSignOut,
    productionRedirectSignOut,
  ] = awsConfig.oauth.redirectSignOut.split(",");

  const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignIn: isLocalhost
        ? localRedirectSignIn
        : productionRedirectSignIn,
      redirectSignOut: isLocalhost
        ? localRedirectSignOut
        : productionRedirectSignOut,
    },
  };

  Amplify.configure(updatedAwsConfig);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          // getUser();
          break;
        case "signOut":
          // setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          return;
      }
    });

    // getUser();

    return unsubscribe;
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" index element={<SignIn />} />
        <Route path="/forgetpassword" index element={<ForgetPassword />} />

        <Route path="/" element={<Layout />}>
          <Route path="profile" element={<Profile />} />

          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="/edit-user/:id" element={<EditUser />} />

          <Route path="courses" element={<Courses />} />
          <Route path="courses/:id" element={<CourseDetails />} />
          <Route path="/add-course" element={<AddCourse />} />
          <Route path="/edit-course/:courseId" element={<AddCourse />} />

          <Route path="livestreams" element={<LiveStreams />} />
          <Route path="/livestreams/:id" element={<LiveStreamDetails />} />
          <Route path="/edit-livestream/:id" element={<EditLiveStream />} />

          <Route path="/add-lesson/:courseId" element={<AddLessons />} />
          <Route path="/edit-lesson/:courseId" element={<AddLessons />} />

          <Route path="course-categories" element={<CourseTags />} />
          <Route path="add-course-category" element={<AddCourseTag />} />
          <Route
            path="edit-course-category/:catId"
            element={<AddCourseTag />}
          />

          <Route path="experts" element={<Experts />} />
          <Route path="expert_detail/:id" element={<ExpertDetail />} />
          <Route path="add-expert-type" element={<AddExpertType />} />
          <Route path="edit-expert-type/:typeId" element={<AddExpertType />} />
          <Route path="expert-types" element={<ExpertTypes />} />
          <Route path="expert/:id" element={<EditExpert />} />

          <Route path="user/:id" element={<UserDetails />} />

          <Route path="posts" element={<Posts />} />
          <Route path="post/:id" element={<PostDetails />} />
          <Route path="edit-post/:postId" element={<EditPost />} />
          <Route path="edit-article/:postId" element={<EditPost />} />
          <Route path="articles" element={<Articles />} />
          <Route path="articles/add" element={<AddPost />} />

          <Route path="post-topics" element={<PostTopic />} />
          <Route path="add-post-topic" element={<AddPostTopic />} />
          <Route path="edit-post-topic/:id" element={<AddPostTopic />} />

          <Route path="transactions" element={<Transactions />} />

          <Route path="callhistory" element={<CallHistory />} />
          {/* <Route path="hotline-credits" element={<Hotline />} /> */}

          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="referral-codes" element={<ReferralCodes />} />
          <Route
            path="referral-purchase-history/:id"
            element={<ReferralPurchaseHistory />}
          />
          <Route path="push-notifications" element={<PushNotifications />} />
        </Route>
        <Route path="/public">
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="faq" element={<Faq />} />
          <Route path="conversation-guidelines" element={<Guidelines />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
