import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import SingleStream from "../components/SingleStream";
import { limit } from "../utils/utils";

const getSessionsInfo = `
    query GetZoomSessions (
        $filter: SearchableZoomSessionFilterInput
        $sort: [SearchableZoomSessionSortInput]
        $limit: Int
        $nextToken: String
    ) {
        searchZoomSessions (
            sort: $sort,
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                sessionId,
                sessionKey,
                sessionName,
                userCount,
                stream,
                record,
                active,
                startTime,
                endTime,
                recordUrls
                thumbnail
            }
            nextToken
            total
        }
    }
`;

const LiveStreams = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [instructor, setInstructor] = useState("");

  const [total, setTotal] = useState(0);
  const [tokens, setTokens] = useState([]);

  const [token, setToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [sort, setSort] = useState({ field: "createdAt", direction: "desc" });

  function handleNext() {
    setNextToken(token);
    setToken(null);
  }

  function handleFirst() {
    let temp = structuredClone(tokens);
    temp.pop();
    temp.pop();
    setTokens(temp);
    setNextToken(temp.at(-1));
  }

  async function getData() {
    try {
      setLoading(true);
      let filter;
      if (search.length !== 0) {
        filter = { sessionName: { matchPhrasePrefix: search } };
      }

      const res = await API.graphql({
        query: getSessionsInfo,
        variables: {
          filter,
          sort: { direction: "desc" },
          limit,
          nextToken,
        },
      });

      const totalSessions = res.data.searchZoomSessions.total;

      setTotal(totalSessions);
      setLoading(false);
      setToken(res.data.searchZoomSessions.nextToken);
      setTokens((prev) => [...prev, res.data?.searchCourses?.nextToken]);

      if (
        res.data.searchZoomSessions.items.length === 0 &&
        search.length === 0
      ) {
        return;
      }

      setSessions(res.data.searchZoomSessions.items);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setLoading(true);
    let debounce = setTimeout(() => {
      getData();
    }, 1200);
    return () => clearTimeout(debounce);
  }, [search, nextToken, instructor, sort?.key, sort?.direction]);

  return (
    <div className="container-fluid" style={{ marginTop: "30px" }}>
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h6 className="header-pretitle">Overview</h6>

          <h1 className="header-title text-truncate">Clarity TV ({total})</h1>
        </div>
      </div>
      <div className="recommended-experts-container-content">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            className="form-control list-search"
                            type="search"
                            placeholder="Search by title"
                            value={search}
                            onChange={(e) => {
                              setTokens([]);
                              setToken(null);
                              setSearch(e.target.value);
                            }}
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                      <div className="col-auto">
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            setToken(null);
                            setTokens([]);
                            setSort({ field: "createdAt", direction: "desc" });
                            setSearch("");
                            setInstructor(null);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <div className="d-flex justify-content-center m-4">
                        <img
                          src="/images/loader.svg"
                          alt="loader"
                          height={"100px"}
                        />
                      </div>
                    ) : (
                      <table className="table table-sm table-hover table-nowrap card-table">
                        <thead>
                          <tr>
                            <th style={{ width: "40rem" }}>
                              <span className="text-muted">Title</span>
                            </th>
                            <th className="text-center">
                              <span className="text-muted">Duration</span>
                            </th>
                            <th className="text-center">
                              <span className="text-muted">Date</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="list fs-base">
                          {sessions.map((session) => (
                            <SingleStream
                              key={session.sessionId}
                              streamData={session}
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                    {sessions.length === 0 && !loading && (
                      <p className="text-center m-3">No streams</p>
                    )}
                    <Pagination
                      handleNext={handleNext}
                      handleFirst={handleFirst}
                      length={tokens.length}
                      token={token}
                      total={sessions?.length}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveStreams;
